import { Card } from '@tremor/react';
import { CardTitle, PageContainer } from '../../components';
import { useAuthenticatedUser } from '../../auth';
import { getSupportEmailAddress } from '../../configuration';
import { SubscriptionCard } from './subscription-card';
import { Link } from 'react-router-dom';
import { RiArrowLeftLine } from '@remixicon/react';

export default function AccountSettingsPage() {
  const { currentUser } = useAuthenticatedUser();

  const isTrial =
    currentUser.status.subscription === 'trialing' ||
    currentUser.status.subscription === 'trial ended';

  return (
    <PageContainer>
      <Link
        to="/"
        className="flex items-center space-x-1 text-tremor-default text-tremor-brand"
      >
        <RiArrowLeftLine className="h-5 w-5" />
        <span>Go back to dashboards</span>
      </Link>
      <SubscriptionCard />
      {!isTrial && (
        <Card>
          <CardTitle title="Invoices" />
          <div className="mt-2 text-tremor-default text-tremor-content">
            If you wish to get your invoices, please send an e-mail{' '}
            <a href={`mailto:${getSupportEmailAddress()}`} className="text-tremor-brand">
              {getSupportEmailAddress()}
            </a>
            . Invoices will be available in the application in the future.
          </div>
        </Card>
      )}
      <Card decoration="top" decorationColor="red">
        <CardTitle title="Danger zone" />
        <div className="mt-2 text-tremor-default text-tremor-content">
          If you wish to delete your account, simply send an e-mail to{' '}
          <a href={`mailto:${getSupportEmailAddress()}`} className="text-tremor-brand">
            {getSupportEmailAddress()}
          </a>
          .
        </div>
      </Card>
    </PageContainer>
  );
}
