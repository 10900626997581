import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { getNowDate } from '../../../../utils';
import { DateRange, Filter } from '../../../../requests/api/common.interfaces';
import {
  useGetCampaignGoalBlock,
  useGetChannelGroupGoalBlock,
  useGetKeyMetricGoalBlock,
  useGetLandingPageGoalBlock,
  useGetEvents,
  useGetSourceGoalBlock,
  useGetFirstUserCampaignGoalBlock,
  useGetFirstUserChannelGroupGoalBlock,
  useGetFirstUserSourceGoalBlock,
  useGetPages,
} from '../../../../requests/api/goal.requests';
import {
  FirstTouchAttributionGoalBlock,
  LastTouchAttributionGoalBlock,
} from './use-goal-block.hook.intefaces';

export function useEvents({
  websiteId,
  queryKey,
  dateRange,
  onInitialLoadingComplete,
}: {
  websiteId: string;
  queryKey: string;
  dateRange: DateRange;
  onInitialLoadingComplete?: () => void;
}) {
  const getEvents = useGetEvents();
  const block = useQuery({
    queryKey: [queryKey],
    queryFn: () => {
      const currentDate = getNowDate();

      return getEvents({
        websiteId,
        currentDate,
        dateRange,
      });
    },
  });

  useEffect(() => {
    block.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websiteId, dateRange]);

  useEffect(() => {
    if (!block.isLoading || block.isLoadingError) {
      onInitialLoadingComplete && onInitialLoadingComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block.isLoading, block.isLoadingError]);

  return block;
}

export function usePages({
  websiteId,
  queryKey,
  dateRange,
  onInitialLoadingComplete,
}: {
  websiteId: string;
  queryKey: string;
  dateRange: DateRange;
  onInitialLoadingComplete?: () => void;
}) {
  const getPages = useGetPages();
  const block = useQuery({
    queryKey: [queryKey],
    queryFn: () => {
      const currentDate = getNowDate();

      return getPages({
        websiteId,
        currentDate,
        dateRange,
      });
    },
  });

  useEffect(() => {
    block.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websiteId, dateRange]);

  useEffect(() => {
    if (!block.isLoading || block.isLoadingError) {
      onInitialLoadingComplete && onInitialLoadingComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block.isLoading, block.isLoadingError]);

  return block;
}

export function useKeyMetricGoalBlock({
  websiteId,
  queryKey,
  dateRange,
  filters,
  goalFilters,
  onInitialLoadingComplete,
}: {
  websiteId: string;
  queryKey: string;
  dateRange: DateRange;
  filters: Filter[];
  goalFilters: Filter[];
  onInitialLoadingComplete?: () => void;
}) {
  const getKeyMetricGoalBlock = useGetKeyMetricGoalBlock();
  const block = useQuery({
    queryKey: [queryKey],
    queryFn: () => {
      const currentDate = getNowDate();

      return getKeyMetricGoalBlock({
        websiteId,
        currentDate,
        dateRange,
        filters,
        goalFilters,
      });
    },
  });

  useEffect(() => {
    block.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websiteId, dateRange, goalFilters]);

  useEffect(() => {
    if (!block.isLoading || block.isLoadingError) {
      onInitialLoadingComplete && onInitialLoadingComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block.isLoading, block.isLoadingError]);

  return block;
}

export function useLastTouchAttributionGoalBlock({
  websiteId,
  queryKey,
  dateRange,
  filters,
  goalFilters,
  blockName,
  onInitialLoadingComplete,
}: {
  websiteId: string;
  queryKey: string;
  dateRange: DateRange;
  filters: Filter[];
  goalFilters: Filter[];
  blockName: 'sources' | 'landingPages' | 'campaigns' | 'channelGroups';
  onInitialLoadingComplete?: () => void;
}) {
  const getSourceGoalBlock = useGetSourceGoalBlock();
  const getLandingPageGoalBlock = useGetLandingPageGoalBlock();
  const getCampaignGoalBlock = useGetCampaignGoalBlock();
  const getChannelGroupGoalBlock = useGetChannelGroupGoalBlock();

  const block = useQuery<LastTouchAttributionGoalBlock>({
    queryKey: [queryKey],
    queryFn: () => {
      const currentDate = getNowDate();

      if (blockName === 'landingPages') {
        return getLandingPageGoalBlock({
          websiteId,
          currentDate,
          dateRange,
          filters,
          goalFilters,
        });
      }

      if (blockName === 'campaigns') {
        return getCampaignGoalBlock({
          websiteId,
          currentDate,
          dateRange,
          filters,
          goalFilters,
        });
      }

      if (blockName === 'channelGroups') {
        return getChannelGroupGoalBlock({
          websiteId,
          currentDate,
          dateRange,
          filters,
          goalFilters,
        });
      }

      return getSourceGoalBlock({
        websiteId,
        currentDate,
        dateRange,
        filters,
        goalFilters,
      });
    },
  });

  useEffect(() => {
    block.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websiteId, dateRange, goalFilters, blockName]);

  useEffect(() => {
    if (!block.isLoading || block.isLoadingError) {
      onInitialLoadingComplete && onInitialLoadingComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block.isLoading, block.isLoadingError]);

  return block;
}

export function useFirstTouchAttributionGoalBlock({
  websiteId,
  queryKey,
  dateRange,
  filters,
  goalFilters,
  blockName,
  onInitialLoadingComplete,
}: {
  websiteId: string;
  queryKey: string;
  dateRange: DateRange;
  filters: Filter[];
  goalFilters: Filter[];
  blockName: 'sources' | 'campaigns' | 'channelGroups';
  onInitialLoadingComplete?: () => void;
}) {
  const getFirstUserSourceGoalBlock = useGetFirstUserSourceGoalBlock();
  const getFirstUserCampaignGoalBlock = useGetFirstUserCampaignGoalBlock();
  const getFirstUserChannelGroupGoalBlock = useGetFirstUserChannelGroupGoalBlock();

  const block = useQuery<FirstTouchAttributionGoalBlock>({
    queryKey: [queryKey],
    queryFn: () => {
      const currentDate = getNowDate();

      if (blockName === 'campaigns') {
        return getFirstUserCampaignGoalBlock({
          websiteId,
          currentDate,
          dateRange,
          filters,
          goalFilters,
        });
      }

      if (blockName === 'channelGroups') {
        return getFirstUserChannelGroupGoalBlock({
          websiteId,
          currentDate,
          dateRange,
          filters,
          goalFilters,
        });
      }

      return getFirstUserSourceGoalBlock({
        websiteId,
        currentDate,
        dateRange,
        filters,
        goalFilters,
      });
    },
  });

  useEffect(() => {
    block.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websiteId, dateRange, goalFilters, blockName]);

  useEffect(() => {
    if (!block.isLoading || block.isLoadingError) {
      onInitialLoadingComplete && onInitialLoadingComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block.isLoading, block.isLoadingError]);

  return block;
}
