import { RiCloseFill } from '@remixicon/react';
import { Badge, BadgeProps } from '@tremor/react';
import { formatFilterKey, formatFilterMatchKey } from '../utils';
import { Filter } from '../../../../../requests/api/common.interfaces';

export default function FilterTags({
  filters,
  onFilterRemoved,
  className,
  color,
}: {
  filters: Filter[];
  onFilterRemoved: (filter: Filter) => void;
  className?: string;
  color?: BadgeProps['color'];
}) {
  return (
    <div className={`${className || ''} flex flex-wrap gap-x-4 gap-y-2`}>
      {filters.map(filter => (
        <Badge key={filter.key} color={color}>
          <span className="flex items-center">
            {formatFilterKey(filter.key)} {formatFilterMatchKey(filter.matchKey)} "
            {filter.value}"
            <RiCloseFill
              className="-mr-1 cursor-pointer"
              size={16}
              onClick={() => onFilterRemoved(filter)}
            />
          </span>
        </Badge>
      ))}
    </div>
  );
}
