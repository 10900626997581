import { useMakeAuthenticatedGetRequest } from '../requests.hooks';

export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  status: {
    account: 'signed-up' | 'ga4-connected';
    subscription:
      | 'trialing'
      | 'trial ended'
      | 'active'
      | 'pending cancellation'
      | 'cancelled';
    canUseApp: boolean;
    canSubscribe: boolean;
    canUnsubscribe: boolean;
    canResubscribe: boolean;
  };
  permissions: string[];
  features: string[];
  subscription: {
    endDate: string | null;
    trialEndDate: string | null;
    plan: {
      id: number;
      name: string;
    };
  };
}

export interface Subscription {
  id: number;
  startDate: string;
  endDate: string | null;
  trialEndDate: string | null;
  status: 'trialing' | 'active' | 'cancelled';
  plan: {
    name: string;
  };
}

export interface Website {
  id: string;
  name: string;
}

export function useListAllUsers(): () => Promise<User[]> {
  const { makeAuthenticatedGetRequest } = useMakeAuthenticatedGetRequest();

  return async () => {
    const { data } = await makeAuthenticatedGetRequest('/api/back-office/users');
    return data;
  };
}

export function useGetUser(): (userId: number) => Promise<{
  user: User;
  subscriptionHistory: Subscription[];
  websites: Website[];
}> {
  const { makeAuthenticatedGetRequest } = useMakeAuthenticatedGetRequest();

  return async userId => {
    const { data } = await makeAuthenticatedGetRequest(
      `/api/back-office/users/${userId}`,
    );
    return data;
  };
}
