import { Callout, Card } from '@tremor/react';
import {
  CardHeader,
  NumberValue,
  PaginationBar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  SkeletonLoader,
} from '../../../../components';
import { useState } from 'react';
import { usePagination, downloadAsCsv } from '../../../../utils';
import { DateRange, Filter } from '../../../../requests/api/common.interfaces';
import { useLastTouchAttributionGoalBlock } from '../common';
import { GoalBlockName } from './last-touch-attribution-goal-card.interfaces';
import {
  getLastTouchAttributionGoalCardDescription,
  formatLastTouchAttributionGoalBlockForCsv,
  pluralTabs,
  singularTabs,
  getLastTouchAttributionGoalBlockCsvFilename,
} from './last-touch-attribution-goal-card.utils';

interface LastTouchAttributionGoalCardProps {
  websiteId: string;
  dateRange: DateRange;
  filters: Filter[];
  goalFilterKey: 'eventName' | 'pagePath';
  goalFilters: Filter[];
}

export default function LastTouchAttributionGoalCard({
  websiteId,
  dateRange,
  filters,
  goalFilterKey,
  goalFilters,
}: LastTouchAttributionGoalCardProps) {
  const [blockName, setBlockName] = useState<GoalBlockName>('sources');
  const [maxItemsPerPage, setMaxItemsPerPage] = useState(10);

  const block = useLastTouchAttributionGoalBlock({
    websiteId,
    dateRange,
    filters,
    goalFilters,
    blockName,
    queryKey: 'lastTouchAttributionBlock',
  });

  const { paginatedItems, currentPageIndex, setCurrentPageIndex, numberOfPages } =
    usePagination({
      items: block.data?.items,
      maxItemsPerPage,
    });

  const downloadBlockAsCsv = () => {
    if (!block.isSuccess || block.isRefetching) return;

    const items = formatLastTouchAttributionGoalBlockForCsv({
      block: block.data,
      blockName,
    });

    const filename = getLastTouchAttributionGoalBlockCsvFilename(blockName);

    downloadAsCsv({ items, filename });
  };

  return (
    <Card>
      <CardHeader
        title="Last-touch attribution"
        isAboveTable
        tabs={pluralTabs}
        selectedTab={blockName}
        onSelectedTabChange={setBlockName}
        isDownloadButtonDisabled={!block.isSuccess}
        onDownloadButtonClick={downloadBlockAsCsv}
        maxItemsPerPage={maxItemsPerPage}
        onMaxItemsPerPageChange={setMaxItemsPerPage}
      />
      <div className="mt-2 overflow-x-auto">
        <div className="px-1 text-center text-tremor-label text-tremor-content sm:text-left">
          {getLastTouchAttributionGoalCardDescription({ blockName, goalFilterKey })}
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell small textAlign="left">
                {singularTabs[blockName]}
              </TableHeaderCell>
              <TableHeaderCell small textAlign="right">
                Visitors
              </TableHeaderCell>
            </TableRow>
          </TableHead>
          {block.isSuccess && (
            <TableBody>
              {paginatedItems.map(item => (
                <TableRow key={item.name}>
                  <TableCell small textAlign="left" bold dark>
                    <span>
                      {block.isRefetching ? (
                        <SkeletonLoader hiddenText={item.name} className="inline" />
                      ) : (
                        item.name
                      )}
                    </span>
                  </TableCell>
                  <TableCell small textAlign="right">
                    <NumberValue
                      values={item.totalConvertedVisitors}
                      isLoading={block.isRefetching}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {block.isSuccess && numberOfPages > 1 && (
          <div className="mt-2">
            <PaginationBar
              numberOfPages={numberOfPages}
              currentPageIndex={currentPageIndex}
              onCurrentPageIndexChange={setCurrentPageIndex}
            />
          </div>
        )}
        {block.isPending && <SkeletonLoader className="mt-2 h-36" />}
        {block.isError && (
          <Callout title="An error has occurred" color="red">
            An error occurred while retrieving the data. Try refreshing the page.
          </Callout>
        )}
      </div>
    </Card>
  );
}
