import { Callout, Card } from '@tremor/react';
import { useTrafficBlock } from '../common';
import {
  formatSecondsInMinutes,
  usePagination,
  formatPercentage,
} from '../../../../utils';
import {
  CardHeader,
  ComparisonBadge,
  NumberValue,
  PaginationBar,
  PercentageBar,
  SkeletonLoader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from '../../../../components';
import { Comparison } from '../../../../requests/api/traffic.requests';
import { useState } from 'react';
import { getFilterKeyFromBlockName } from './acquistion-card.utils';
import { RiFilterLine } from '@remixicon/react';
import { DateRange, Filter } from '../../../../requests/api/common.interfaces';

export type AcquisitionBlockName = 'sources' | 'mediums' | 'campaigns';

const singularTabs: Record<AcquisitionBlockName, string> = {
  sources: 'Source',
  mediums: 'Medium',
  campaigns: 'Campaign',
};

const pluralTabs: Record<AcquisitionBlockName, string> = {
  sources: 'Sources',
  mediums: 'Mediums',
  campaigns: 'Campaigns',
};

interface AcquisitionCardProps {
  websiteId: string;
  dateRange: DateRange;
  filters: Filter[];
  comparison: Comparison | undefined;
  onAddFilter: (filters: Filter) => void;
}

export default function AcquisitionCard({
  websiteId,
  dateRange,
  filters,
  comparison,
  onAddFilter,
}: AcquisitionCardProps) {
  const [blockName, setBlockName] = useState<AcquisitionBlockName>('sources');
  const [maxItemsPerPage, setMaxItemsPerPage] = useState(10);

  const block = useTrafficBlock({
    blockName,
    websiteId,
    dateRange,
    filters,
    comparison,
    queryKey: 'acquisitionBlock',
  });

  const { paginatedItems, currentPageIndex, setCurrentPageIndex, numberOfPages } =
    usePagination({
      items: block.data?.items,
      maxItemsPerPage,
    });

  const addFilter = (value: string) => {
    const filter: Filter = {
      key: getFilterKeyFromBlockName(blockName),
      matchKey: 'equals',
      value,
    };

    onAddFilter(filter);
  };

  return (
    <Card>
      <CardHeader
        title="Acquisition"
        isAboveTable
        tabs={pluralTabs}
        selectedTab={blockName}
        onSelectedTabChange={setBlockName}
        maxItemsPerPage={maxItemsPerPage}
        onMaxItemsPerPageChange={setMaxItemsPerPage}
      />
      <div className="mt-2 overflow-x-auto">
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell small textAlign="left">
                {singularTabs[blockName]}
              </TableHeaderCell>
              <TableHeaderCell small />
              <TableHeaderCell small>Visits</TableHeaderCell>
              <TableHeaderCell small>Engagement rate</TableHeaderCell>
              <TableHeaderCell small>Visit duration</TableHeaderCell>
              <TableHeaderCell small>Visitors</TableHeaderCell>
              <TableHeaderCell small>Visits (%)</TableHeaderCell>
            </TableRow>
          </TableHead>
          {block.isSuccess && (
            <TableBody>
              {paginatedItems.map(item => (
                <TableRow key={item.name}>
                  <TableCell small textAlign="left" bold dark>
                    <span className="hover-container flex items-center">
                      {block.isRefetching ? (
                        <SkeletonLoader hiddenText={item.name} className="inline" />
                      ) : (
                        item.name
                      )}
                      <span className="grow" />
                      <span className="hover-element invisible">
                        <button title="Filter on this source">
                          <RiFilterLine
                            className="h-4 w-4"
                            onClick={() => addFilter(item.name)}
                          />
                        </button>
                      </span>
                    </span>
                  </TableCell>
                  <TableCell small>
                    <ComparisonBadge
                      values={item.visits}
                      isLoading={block.isRefetching}
                    />
                  </TableCell>
                  <TableCell small dark>
                    <NumberValue values={item.visits} isLoading={block.isRefetching} />
                  </TableCell>
                  <TableCell small>
                    <NumberValue
                      values={item.engagementRate}
                      valueFormatter={formatPercentage}
                      isLoading={block.isRefetching}
                    />
                  </TableCell>
                  <TableCell small>
                    <NumberValue
                      values={item.visitDuration}
                      valueFormatter={formatSecondsInMinutes}
                      isLoading={block.isRefetching}
                    />
                  </TableCell>
                  <TableCell small>
                    <NumberValue values={item.visitors} isLoading={block.isRefetching} />
                  </TableCell>
                  <TableCell small>
                    <PercentageBar
                      values={item.visits}
                      maxValue={block.data.maxVisitsPerItem}
                      isLoading={block.isRefetching}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {block.isSuccess && numberOfPages > 1 && (
          <div className="mt-2">
            <PaginationBar
              numberOfPages={numberOfPages}
              currentPageIndex={currentPageIndex}
              onCurrentPageIndexChange={setCurrentPageIndex}
            />
          </div>
        )}
        {block.isPending && <SkeletonLoader className="mt-2 h-36" />}
        {block.isError && (
          <Callout title="An error has occurred" color="red">
            An error occurred while retrieving the data. Try refreshing the page.
          </Callout>
        )}
      </div>
    </Card>
  );
}
