import { CurrentUser } from '../../../auth';
import { formatSubscriptionDate } from '../../../utils';

export function getSubscriptionDescription({
  status,
  subscription,
  timeZone,
}: {
  status: CurrentUser['status']['subscription'];
  subscription: {
    trialEndDate: string | null;
    endDate: string | null;
  };
  timeZone?: string;
}): string {
  switch (status) {
    case 'trialing':
      return `You're currently on a free trial. Your trial will end on ${formatSubscriptionDate({ date: subscription.trialEndDate, timeZone })}.`;
    case 'trial ended':
      return `Your free trial ended on ${formatSubscriptionDate({ date: subscription.trialEndDate, timeZone })}. Subscribe now to regain access to Linito.`;
    case 'active':
      return `Your subscription is active.`;
    case 'pending cancellation':
      return `Your subscription is set to end on ${formatSubscriptionDate({ date: subscription.endDate, timeZone })}. You still have full access until then.`;
    case 'cancelled':
      // return `Your subscription ended on ${formatSubscriptionDate({ date: subscription.endDate, timeZone })}. You can resubscribe at any time if you wish to continue using Linito.`;
      return `Your subscription ended on ${formatSubscriptionDate({ date: subscription.endDate, timeZone })}. Please contact support if you wish to resubscribe.`;
    default:
      return "We couldn't retrieve your subscription status. Please contact support for assistance.";
  }
}
