import * as papaparse from 'papaparse';

export function downloadAsCsv<T>({ items, filename }: { items: T[]; filename: string }) {
  const csv = papaparse.unparse(items);

  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();

  URL.revokeObjectURL(url);
}
