import { Callout, Card, TextInput } from '@tremor/react';
import { Button, CardTitle, PageContainer } from '../../components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { productAnalytics } from '../../external-services';
import { useSubscribe } from '../../requests/api/account.requests';
import { useMutation } from '@tanstack/react-query';
import { useAuthenticatedUser } from '../../auth';

export default function SubscriptionWithCodePage() {
  const { currentUser } = useAuthenticatedUser();

  const [subscriptionCode, setSubscriptionCode] = useState<string>('');
  const navigate = useNavigate();

  const subscribe = useSubscribe();
  const subscribeMutation = useMutation({
    mutationFn: async ({ subscriptionCodeName }: { subscriptionCodeName: string }) => {
      productAnalytics.sendEvent('start_subscribe', {
        plan: 'Pro',
        source: 'code',
      });
      await subscribe({ subscriptionCodeName });
    },
    onSuccess: () => {
      productAnalytics.sendEvent('subscribe', {
        plan: 'Pro',
        source: 'code',
      });
      navigate('/account-settings');
    },
  });

  return (
    <PageContainer>
      <div className="flex grow items-center justify-center">
        <Card className="max-w-sm">
          <CardTitle title="Activate a subscription" />
          {currentUser.status.canSubscribe ? (
            <div className="mt-4 space-y-3">
              {subscribeMutation.isError && (
                <Callout title="An error has occurred" color="red">
                  An error occurred while activating your subscription. Try refreshing the
                  page or contact support.
                </Callout>
              )}
              <p className="text-tremor-default text-tremor-content">
                Enter your license key to active your subscription.
              </p>
              <div className="flex space-x-2">
                <TextInput
                  placeholder=" "
                  value={subscriptionCode}
                  onValueChange={setSubscriptionCode}
                  disabled={subscribeMutation.isPending}
                />
                <Button
                  onClick={() =>
                    subscribeMutation.mutate({ subscriptionCodeName: subscriptionCode })
                  }
                  variant="neutral"
                  disabled={!subscriptionCode || subscribeMutation.isPending}
                >
                  Validate
                </Button>
              </div>
            </div>
          ) : (
            <p className="mt-4 text-tremor-default text-tremor-content">
              Impossible to activate a subscription with a license key. Please contact
              support.
            </p>
          )}
        </Card>
      </div>
    </PageContainer>
  );
}
