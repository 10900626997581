import { FirstTouchAttributionGoalBlock } from '../common';
import { GoalBlockName } from './first-touch-attribution-goal-card.interfaces';

export const singularTabs: Record<GoalBlockName, string> = {
  sources: 'Source',
  campaigns: 'Campaign',
  channelGroups: 'Channel group',
};

export const pluralTabs: Record<GoalBlockName, string> = {
  sources: 'Sources',
  campaigns: 'Campaigns',
  channelGroups: 'Distribution',
};

export function getFirstTouchAttributionGoalCardDescription({
  blockName,
  goalFilterKey,
}: {
  blockName: GoalBlockName;
  goalFilterKey: 'eventName' | 'pagePath';
}): string {
  return `${pluralTabs[blockName]} of first visit of visitors who ${goalFilterKey === 'eventName' ? 'triggered the event' : 'viewed the page'} at least once in this date range.`;
}

export function getFirstTouchAttributionGoalBlockCsvFilename(blockName: GoalBlockName) {
  return `${pluralTabs[blockName].toLowerCase().replaceAll(' ', '-')}-first-touch.csv`;
}

export function formatFirstTouchAttributionGoalBlockForCsv({
  block,
  blockName,
}: {
  block: FirstTouchAttributionGoalBlock;
  blockName: GoalBlockName;
}) {
  return block.items.map(({ name, totalConvertedVisitors }) => ({
    [singularTabs[blockName]]: name,
    Visitors: totalConvertedVisitors.value === null ? '-' : totalConvertedVisitors.value,
  }));
}
