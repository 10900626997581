import { LastTouchAttributionGoalBlock } from '../common';
import { GoalBlockName } from './last-touch-attribution-goal-card.interfaces';

export const singularTabs: Record<GoalBlockName, string> = {
  sources: 'Source',
  landingPages: 'Landing page',
  campaigns: 'Campaign',
  channelGroups: 'Channel group',
};

export const pluralTabs: Record<GoalBlockName, string> = {
  sources: 'Sources',
  landingPages: 'Landing pages',
  campaigns: 'Campaigns',
  channelGroups: 'Distribution',
};

export function getLastTouchAttributionGoalCardDescription({
  blockName,
  goalFilterKey,
}: {
  blockName: GoalBlockName;
  goalFilterKey: 'eventName' | 'pagePath';
}): string {
  return `${pluralTabs[blockName]} of visits where visitors ${goalFilterKey === 'eventName' ? 'triggered the event' : 'viewed the page'} at least once in this date range.`;
}

export function getLastTouchAttributionGoalBlockCsvFilename(blockName: GoalBlockName) {
  return `${pluralTabs[blockName].toLowerCase().replaceAll(' ', '-')}-last-touch.csv`;
}

export function formatLastTouchAttributionGoalBlockForCsv({
  block,
  blockName,
}: {
  block: LastTouchAttributionGoalBlock;
  blockName: GoalBlockName;
}) {
  return block.items.map(({ name, totalConvertedVisitors }) => ({
    [singularTabs[blockName]]: name,
    Visitors: totalConvertedVisitors.value === null ? '-' : totalConvertedVisitors.value,
  }));
}
