import { Callout, Card, DonutChart, List, ListItem } from '@tremor/react';
import { useTrafficBlock } from '../common';
import { formatNumber } from '../../../../utils';
import { Comparison } from '../../../../requests/api/traffic.requests';
import { CardTitle, NumberValue, SkeletonLoader } from '../../../../components';
import { RiFilterLine } from '@remixicon/react';
import { DateRange, Filter } from '../../../../requests/api/common.interfaces';

function classNames(...classNames: string[]): string {
  return classNames.join(' ');
}

interface DistributionCardProps {
  websiteId: string;
  dateRange: DateRange;
  filters: Filter[];
  comparison: Comparison | undefined;
  onInitialLoadingComplete: () => void;
  onAddFilter: (filters: Filter) => void;
}

export default function DistributionCard({
  websiteId,
  dateRange,
  filters,
  comparison,
  onInitialLoadingComplete,
  onAddFilter,
}: DistributionCardProps) {
  const block = useTrafficBlock({
    blockName: 'distribution',
    websiteId,
    dateRange,
    filters,
    comparison,
    queryKey: 'distributionBlock',
    onInitialLoadingComplete,
  });

  const colors = ['cyan', 'blue', 'indigo', 'violet', 'green', 'yellow'];
  const disabledColors = colors.map(() => 'loading');

  const filterChannelGroup = (value: string) => {
    const filter: Filter = {
      key: 'channelGroup',
      matchKey: 'equals',
      value,
    };

    onAddFilter(filter);
  };

  return (
    <Card className="space-y-6">
      <CardTitle title="Distribution" />

      <div>
        <p className="flex items-center justify-between text-tremor-label text-tremor-content dark:text-dark-tremor-content">
          <span>Channel group</span>
          <span>Visits</span>
        </p>
        {block.isPending && <SkeletonLoader className="mt-2 h-56" />}
        {block.isError && (
          <Callout title="An error has occurred" color="red" className="mt-4">
            An error occurred while retrieving the data. Try refreshing the page.
          </Callout>
        )}
        {block.isSuccess && (
          <List className="mt-2">
            {block.data.channelGroups.map((channelGroup, index) => (
              <ListItem key={channelGroup.name}>
                <span className="hover-container flex grow items-center truncate">
                  <span
                    className={classNames(
                      block.isRefetching ? 'bg-loading' : `bg-${colors[index]}-500`,
                      'element-to-be-replaced-on-hover mr-2.5 h-2.5 w-2.5 shrink-0 rounded-sm',
                    )}
                    aria-hidden={true}
                  />
                  <span className="element-to-be-replacing-on-hover -ml-0.5 mr-1.5 hidden">
                    <button
                      title="Filter on this channel group"
                      className="flex items-center"
                    >
                      <RiFilterLine
                        className="h-4 w-4"
                        onClick={() => filterChannelGroup(channelGroup.name)}
                      />
                    </button>
                  </span>
                  <span className="truncate" title={channelGroup.name}>
                    {block.isRefetching ? (
                      <SkeletonLoader hiddenText={channelGroup.name} />
                    ) : (
                      channelGroup.name
                    )}
                  </span>
                  <span className="grow" />
                </span>

                <span className="font-medium text-gray-900">
                  <NumberValue
                    className="text-right"
                    values={channelGroup.visits}
                    isLoading={block.isRefetching}
                    showComparisonBadgeUponComparison
                  />
                </span>
              </ListItem>
            ))}
          </List>
        )}
      </div>

      {block.isSuccess && (
        <div className="flex justify-center space-x-6">
          <div className="w-24">
            <DonutChart
              data={block.data.channelGroups.map(({ name, visits }) => ({
                name,
                visits: visits.value,
              }))}
              variant="pie"
              category="visits"
              index="name"
              valueFormatter={formatNumber}
              className="h-24"
              colors={block.isRefetching ? disabledColors : colors}
            />
          </div>
          {block.data.channelGroups[0]?.visits.comparisonValue !== undefined && (
            <div className="w-24">
              <DonutChart
                data={block.data.channelGroups.map(({ name, visits }) => ({
                  name,
                  visits: visits.comparisonValue,
                }))}
                variant="pie"
                category="visits"
                index="name"
                valueFormatter={formatNumber}
                className="h-24"
                colors={block.isRefetching ? disabledColors : colors}
              />
            </div>
          )}
        </div>
      )}
    </Card>
  );
}
