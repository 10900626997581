import { Select, SelectItem, TextInput, Button as TremorButton } from '@tremor/react';
import { useState } from 'react';
import { formatFilterKey, formatFilterMatchKey } from '../utils';
import { FilterDefinition } from '../../../../../requests/api/traffic.requests';
import { LightButton, Button } from '../../../../../components';
import { RiCloseLine } from '@remixicon/react';
import { Filter } from '../../../../../requests/api/common.interfaces';

export default function AddFilterForm({
  filterDefinitions,
  className,
  onAddFilter,
  onAddComparisonFilter,
  onCancel,
}: {
  filterDefinitions: FilterDefinition[];
  className?: string;
  onAddFilter: (filter: Filter) => void;
  onAddComparisonFilter?: (filter: Filter) => void;
  onCancel: () => void;
}) {
  const [filterKey, setFilterKey] = useState<string | undefined>(undefined);
  const [filterMatchKey, setFilterMatchKey] = useState<string | undefined>(undefined);
  const [filterValue, setFilterValue] = useState<string | undefined>(undefined);

  const onFilterKeyChange = (key: string) => {
    setFilterKey(key);

    const firstMatchType = filterDefinitions.find(filter => filter.key === key)
      ?.matchTypes[0].key;
    setFilterMatchKey(firstMatchType || '');

    setFilterValue('');
  };

  const onSubmit = () => {
    if (onAddComparisonFilter) {
      onBaseSubmit();
      onComparisonSubmit();
      return;
    }

    onBaseSubmit();
  };

  const onBaseSubmit = () => {
    if (filterKey && filterMatchKey && filterValue) {
      onAddFilter({ key: filterKey, matchKey: filterMatchKey, value: filterValue });

      setFilterKey('');
      setFilterMatchKey('');
      setFilterValue('');
    }
  };

  const onComparisonSubmit = () => {
    if (filterKey && filterMatchKey && filterValue) {
      onAddComparisonFilter &&
        onAddComparisonFilter({
          key: filterKey,
          matchKey: filterMatchKey,
          value: filterValue,
        });

      setFilterKey('');
      setFilterMatchKey('');
      setFilterValue('');
    }
  };

  const matchTypes =
    filterDefinitions.find(filter => filter.key === filterKey)?.matchTypes || [];

  const canFilterBeSubmitted = filterKey && filterMatchKey && filterValue;

  return (
    <div className={`${className || ''} flex`}>
      <div
        className={`flex w-full flex-col gap-4 rounded-lg bg-white p-2 shadow-md ${onAddComparisonFilter ? 'lg:w-auto lg:flex-row' : 'md:w-auto md:flex-row'}`}
      >
        <Select
          placeholder="Select parameter..."
          value={filterKey}
          onValueChange={onFilterKeyChange}
        >
          {filterDefinitions.map(filter => (
            <SelectItem key={filter.key} value={filter.key}>
              {formatFilterKey(filter.key)}
            </SelectItem>
          ))}
        </Select>
        <Select
          placeholder="..."
          disabled={!filterKey}
          value={filterMatchKey}
          onValueChange={setFilterMatchKey}
        >
          {matchTypes.map(matchType => (
            <SelectItem key={matchType.key} value={matchType.key}>
              {formatFilterMatchKey(matchType.key)}
            </SelectItem>
          ))}
        </Select>
        <TextInput
          placeholder="..."
          disabled={!filterKey}
          value={filterValue}
          onValueChange={setFilterValue}
        />
        <Button className="px-3.5" disabled={!canFilterBeSubmitted} onClick={onSubmit}>
          Apply
        </Button>
        {onAddComparisonFilter && (
          <TremorButton
            variant="secondary"
            disabled={!canFilterBeSubmitted}
            onClick={onBaseSubmit}
          >
            Apply only to main
          </TremorButton>
        )}
        {onAddComparisonFilter && (
          <TremorButton
            variant="secondary"
            color="amber"
            disabled={!canFilterBeSubmitted}
            onClick={onComparisonSubmit}
          >
            Apply only to comparison
          </TremorButton>
        )}
        <LightButton
          className="-ml-2 flex items-center justify-center"
          onClick={onCancel}
        >
          <RiCloseLine className="h-4 w-4" />
        </LightButton>
      </div>
    </div>
  );
}
