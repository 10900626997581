import { SectionNavbar } from '../common';
import { useOutletContext } from 'react-router-dom';
import { SectionContext } from '../section-container';
import { KeyMetricGoalCard } from './key-metric-goal-card';
import { LastTouchAttributionGoalCard } from './last-touch-attribution-goal-card';
import { GoalFilter, GoalFilterBar } from './goal-filter-bar';
import { useState } from 'react';
import { Comparison } from '../../../requests/api/traffic.requests';
import { FirstTouchAttributionGoalCard } from './first-touch-attribution-goal-card';
import { Filter } from '../../../requests/api/common.interfaces';

export default function GoalSection() {
  /* Navbar */
  const { websiteId, websites, dateRange, setWebsiteId, setDateRange } =
    useOutletContext<SectionContext>();
  const [filters, setFilters] = useState<Filter[]>([]);
  const [comparison, setComparison] = useState<Comparison | undefined>(undefined);

  const [goalFilter, setGoalFilter] = useState<GoalFilter | null>(null);

  const goalFilters: GoalFilter[] = goalFilter ? [goalFilter] : [];

  return (
    <div className="space-y-6">
      <SectionNavbar
        websites={websites}
        websiteId={websiteId}
        dateRange={dateRange}
        filters={filters}
        comparison={comparison}
        onWebsiteIdChange={setWebsiteId}
        onDateRangeChange={setDateRange}
        onFiltersChange={setFilters}
        onComparisonChange={setComparison}
      />
      <GoalFilterBar
        websiteId={websiteId}
        dateRange={dateRange}
        goalFilter={goalFilter}
        onGoalFilterChange={setGoalFilter}
      />
      {goalFilter ? (
        <div className="grid grid-cols-4 gap-6">
          <div className="col-span-4 lg:col-span-1">
            <KeyMetricGoalCard
              websiteId={websiteId}
              dateRange={dateRange}
              filters={filters}
              goalFilters={goalFilters}
            />
          </div>
          <div className="col-span-4 space-y-6 lg:col-span-3">
            <LastTouchAttributionGoalCard
              websiteId={websiteId}
              dateRange={dateRange}
              filters={filters}
              goalFilterKey={goalFilter.key}
              goalFilters={goalFilters}
            />
            <FirstTouchAttributionGoalCard
              websiteId={websiteId}
              dateRange={dateRange}
              filters={filters}
              goalFilterKey={goalFilter.key}
              goalFilters={goalFilters}
            />
          </div>
        </div>
      ) : (
        <div className="space-y-2 px-2 text-center text-tremor-label text-tremor-content">
          <div>Find out which visitors have performed a specific action, such as:</div>
          <div className="flex items-center justify-center">
            <ul className="list-disc text-left">
              <li>booking a demo,</li>
              <li>creating an account,</li>
              <li>subscribing to a newsletter,</li>
              <li>filling in a contact form.</li>
            </ul>
          </div>
          <div>These actions can be measured either by an event or by a page view.</div>
        </div>
      )}
    </div>
  );
}
