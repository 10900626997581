import { useMakeAuthenticatedGetRequest } from '../requests.hooks';
import { DateRange, Filter } from './common.interfaces';

export type AggregationStep = 'hour' | 'day' | 'month';

type BrowserBlock = {
  items: {
    name: string;
    visits: { value: number };
  }[];
};

type CampaignBlock = {
  items: {
    name: string;
    visits: {
      value: number | null;
      comparisonValue?: number | null;
    };
    visitDuration: {
      value: number | null;
      comparisonValue?: number | null;
    };
    visitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
    engagementRate: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
  maxVisitsPerItem: number | null;
};

type CityBlock = {
  items: {
    name: string;
    visits: { value: number };
  }[];
};

type CountryBlock = {
  items: {
    name: string;
    visits: { value: number };
  }[];
};

type DeviceCategoryBlock = {
  items: {
    name: string;
    visits: { value: number };
  }[];
};

type DistributionBlock = {
  channelGroups: {
    name: string;
    visits: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
};

type EventBlock = {
  items: {
    name: string;
  }[];
};

type LandingPageBlock = {
  items: {
    path: string;
    views: {
      value: number | null;
      comparisonValue?: number | null;
    };
    visitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
    engagementRate: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
  maxViewsPerItem: number | null;
};

type KeyMetricBlock = {
  totalVisitors: {
    value: number | null;
    comparisonValue?: number | null;
  };
  totalVisits: {
    value: number | null;
    comparisonValue?: number | null;
  };
  totalPageViews: {
    value: number | null;
    comparisonValue?: number | null;
  };
  averagePageViewsPerVisit: {
    value: number | null;
    comparisonValue?: number | null;
  };
  averageVisitDuration: {
    value: number | null;
    comparisonValue?: number | null;
  };
  totalNewVisitors: {
    value: number | null;
    comparisonValue?: number | null;
  };
  totalOtherVisitors: {
    value: number | null;
    comparisonValue?: number | null;
  };
  engagementRate: {
    value: number | null;
    comparisonValue?: number | null;
  };
};

type LanguageBlock = {
  items: {
    name: string;
    visits: { value: number };
  }[];
};

type MediumBlock = {
  items: {
    name: string;
    visits: {
      value: number | null;
      comparisonValue?: number | null;
    };
    visitDuration: {
      value: number | null;
      comparisonValue?: number | null;
    };
    visitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
    engagementRate: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
  maxVisitsPerItem: number | null;
};

type OperatingSystemBlock = {
  items: {
    name: string;
    visits: { value: number };
  }[];
};

type PageBlock = {
  items: {
    path: string;
    views: {
      value: number | null;
      comparisonValue?: number | null;
    };
    visitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
    engagementRate: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
  maxViewsPerItem: number | null;
};

type RegionBlock = {
  items: {
    name: string;
    visits: { value: number };
  }[];
};

type ScreenResolutionBlock = {
  items: {
    name: string;
    visits: { value: number };
  }[];
};

type SourceBlock = {
  items: {
    name: string;
    visits: {
      value: number | null;
      comparisonValue?: number | null;
    };
    visitDuration: {
      value: number | null;
      comparisonValue?: number | null;
    };
    visitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
    engagementRate: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
  maxVisitsPerItem: number | null;
};

type TimeSeriesBlock = {
  timeSeries: {
    dateTime: string;
    data: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
  aggregationStep: AggregationStep;
};

type VisitBlock = TimeSeriesBlock;

type PageViewBlock = TimeSeriesBlock;

type PageViewsPerVisitBlock = TimeSeriesBlock;

type VisitDurationBlock = TimeSeriesBlock;

type VisitorBlock = TimeSeriesBlock;

type EngagementRateBlock = TimeSeriesBlock;

type TrafficBlocks = {
  browsers: { block: BrowserBlock };
  campaigns: { block: CampaignBlock };
  cities: { block: CityBlock };
  countries: { block: CountryBlock };
  deviceCategories: { block: DeviceCategoryBlock };
  distribution: { block: DistributionBlock };
  engagementRate: { block: EngagementRateBlock };
  events: { block: EventBlock };
  landingPages: { block: LandingPageBlock };
  keyMetrics: { block: KeyMetricBlock };
  languages: { block: LanguageBlock };
  mediums: { block: MediumBlock };
  operatingSystems: { block: OperatingSystemBlock };
  pages: { block: PageBlock };
  pageViews: { block: PageViewBlock };
  pageViewsPerVisit: { block: PageViewsPerVisitBlock };
  regions: { block: RegionBlock };
  screenResolutions: { block: ScreenResolutionBlock };
  sources: { block: SourceBlock };
  visitDuration: { block: VisitDurationBlock };
  visitors: { block: VisitorBlock };
  visits: { block: VisitBlock };
};

export type TrafficBlockName = keyof TrafficBlocks;

export interface Comparison {
  dateRange: DateRange;
  filters: Filter[];
}

export interface FilterDefinition {
  key: string;
  matchTypes: {
    key: string;
  }[];
}

export function useGetTrafficBlock(): <T extends TrafficBlockName>({
  blockName,
  websiteId,
  dateRange,
  currentDate,
  filters,
  comparison,
}: {
  blockName: T;
  websiteId: string;
  dateRange: DateRange;
  currentDate: string;
  filters: Filter[];
  comparison?: {
    dateRange: DateRange;
    filters: Filter[];
  };
}) => Promise<TrafficBlocks[T]['block']> {
  const { makeAuthenticatedGetRequest } = useMakeAuthenticatedGetRequest();

  return async ({
    blockName,
    websiteId,
    dateRange,
    currentDate,
    filters,
    comparison,
  }) => {
    const searchParams = new URLSearchParams();

    searchParams.append('websiteId', websiteId);
    searchParams.append('currentDate', currentDate);
    searchParams.append('dateRange', JSON.stringify(dateRange));
    searchParams.append('filters', JSON.stringify(filters));

    if (comparison) {
      searchParams.append('comparison', JSON.stringify(comparison));
    }

    const { data } = await makeAuthenticatedGetRequest(
      `/api/traffic/blocks/${blockName}?${searchParams.toString()}`,
    );
    return data;
  };
}

export function useGetFilterDefinitions(): () => Promise<FilterDefinition[]> {
  const { makeAuthenticatedGetRequest } = useMakeAuthenticatedGetRequest();

  return async () => {
    const { data } = await makeAuthenticatedGetRequest('/api/traffic/filter-definitions');
    return data;
  };
}
