import { Callout, Card } from '@tremor/react';
import { CardTitle, NumberValue, SkeletonLoader } from '../../../../components';
import { DateRange, Filter } from '../../../../requests/api/common.interfaces';
import { useKeyMetricGoalBlock } from '../common';
import { formatPercentage } from '../../../../utils';

interface KeyMetricGoalCardProps {
  websiteId: string;
  dateRange: DateRange;
  filters: Filter[];
  goalFilters: Filter[];
}

export default function KeyMetricGoalCard({
  websiteId,
  dateRange,
  filters,
  goalFilters,
}: KeyMetricGoalCardProps) {
  const block = useKeyMetricGoalBlock({
    websiteId,
    dateRange,
    filters,
    goalFilters,
    queryKey: 'keyMetricGoalBlock',
  });

  return (
    <Card>
      <CardTitle title="Overview" />
      {block.isPending && <SkeletonLoader className="mt-4 h-16" />}
      {block.isError && (
        <Callout title="An error has occurred" color="red" className="mt-4">
          An error occurred while retrieving the data. Try refreshing the page.
        </Callout>
      )}
      {block.isSuccess && (
        <div className="mt-4 flex justify-around gap-6 lg:flex-col">
          <div className="space-y-1">
            <div className="text-center text-tremor-metric text-tremor-brand">
              <NumberValue
                values={block.data.totalConvertedVisitors}
                isLoading={block.isRefetching}
                showComparisonBadgeUponComparison
              />
            </div>
            <div className="text-center text-tremor-label text-tremor-content">
              Visitors
            </div>
          </div>
          <div className="space-y-1">
            <div className="text-center text-tremor-metric text-tremor-brand">
              <NumberValue
                values={block.data.conversionRate}
                valueFormatter={formatPercentage}
                isLoading={block.isRefetching}
                showComparisonBadgeUponComparison
              />
            </div>
            <div className="text-center text-tremor-label text-tremor-content">
              Conversion
            </div>
          </div>
        </div>
      )}
    </Card>
  );
}
