import { useMakeAuthenticatedGetRequest } from '../requests.hooks';
import { DateRange, Filter } from './common.interfaces';

export type CampaignGoalBlock = {
  items: {
    name: string;
    totalConvertedVisitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
};

export type ChannelGroupGoalBlock = {
  items: {
    name: string;
    totalConvertedVisitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
};

export type FirstUserCampaignGoalBlock = {
  items: {
    name: string;
    totalConvertedVisitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
};

export type FirstUserChannelGroupGoalBlock = {
  items: {
    name: string;
    totalConvertedVisitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
};

export type FirstUserSourceGoalBlock = {
  items: {
    name: string;
    totalConvertedVisitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
};

export type KeyMetricGoalBlock = {
  totalConvertedVisitors: {
    value: number | null;
    comparisonValue?: number | null;
  };
  conversionRate: {
    value: number | null;
    comparisonValue?: number | null;
  };
};

export type SourceGoalBlock = {
  items: {
    name: string;
    totalConvertedVisitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
};

export type EventBlock = {
  items: {
    name: string;
  }[];
};

export type PageBlock = {
  items: {
    path: string;
  }[];
};

export type LandingPageGoalBlock = {
  items: {
    name: string;
    totalConvertedVisitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
};

export function useGetEvents(): ({
  websiteId,
  dateRange,
  currentDate,
}: {
  websiteId: string;
  dateRange: DateRange;
  currentDate: string;
}) => Promise<EventBlock> {
  const { makeAuthenticatedGetRequest } = useMakeAuthenticatedGetRequest();

  return async ({ websiteId, dateRange, currentDate }) => {
    const searchParams = new URLSearchParams();

    searchParams.append('websiteId', websiteId);
    searchParams.append('currentDate', currentDate);
    searchParams.append('dateRange', JSON.stringify(dateRange));

    const { data } = await makeAuthenticatedGetRequest(
      `/api/goal/events?${searchParams.toString()}`,
    );
    return data;
  };
}

export function useGetPages(): ({
  websiteId,
  dateRange,
  currentDate,
}: {
  websiteId: string;
  dateRange: DateRange;
  currentDate: string;
}) => Promise<PageBlock> {
  const { makeAuthenticatedGetRequest } = useMakeAuthenticatedGetRequest();

  return async ({ websiteId, dateRange, currentDate }) => {
    const searchParams = new URLSearchParams();

    searchParams.append('websiteId', websiteId);
    searchParams.append('currentDate', currentDate);
    searchParams.append('dateRange', JSON.stringify(dateRange));

    const { data } = await makeAuthenticatedGetRequest(
      `/api/goal/pages?${searchParams.toString()}`,
    );
    return data;
  };
}

function createUseGetGoalBlock<Block>({
  endpoint,
}: {
  endpoint: string;
}): () => ({
  websiteId,
  dateRange,
  currentDate,
  filters,
  goalFilters,
}: {
  websiteId: string;
  dateRange: DateRange;
  currentDate: string;
  filters: Filter[];
  goalFilters: Filter[];
}) => Promise<Block> {
  return () => {
    const { makeAuthenticatedGetRequest } = useMakeAuthenticatedGetRequest();

    return async ({ websiteId, dateRange, currentDate, filters, goalFilters }) => {
      const searchParams = new URLSearchParams();

      searchParams.append('websiteId', websiteId);
      searchParams.append('currentDate', currentDate);
      searchParams.append('dateRange', JSON.stringify(dateRange));
      searchParams.append('filters', JSON.stringify(filters));
      searchParams.append('goalFilters', JSON.stringify(goalFilters));

      const { data } = await makeAuthenticatedGetRequest(
        `/api/goal/blocks/${endpoint}?${searchParams.toString()}`,
      );
      return data;
    };
  };
}

export const useGetCampaignGoalBlock = createUseGetGoalBlock<CampaignGoalBlock>({
  endpoint: 'campaigns',
});

export const useGetChannelGroupGoalBlock = createUseGetGoalBlock<ChannelGroupGoalBlock>({
  endpoint: 'channel-groups',
});

export const useGetFirstUserCampaignGoalBlock =
  createUseGetGoalBlock<FirstUserCampaignGoalBlock>({
    endpoint: 'first-user-campaigns',
  });

export const useGetFirstUserChannelGroupGoalBlock =
  createUseGetGoalBlock<FirstUserChannelGroupGoalBlock>({
    endpoint: 'first-user-channel-groups',
  });

export const useGetFirstUserSourceGoalBlock =
  createUseGetGoalBlock<FirstUserSourceGoalBlock>({
    endpoint: 'first-user-sources',
  });

export const useGetKeyMetricGoalBlock = createUseGetGoalBlock<KeyMetricGoalBlock>({
  endpoint: 'key-metrics',
});

export const useGetLandingPageGoalBlock = createUseGetGoalBlock<LandingPageGoalBlock>({
  endpoint: 'landing-pages',
});

export const useGetSourceGoalBlock = createUseGetGoalBlock<SourceGoalBlock>({
  endpoint: 'sources',
});
