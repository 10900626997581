import { useAuth } from '../../auth';
import { useMakeAuthenticatedPostRequest } from '../requests.hooks';

export function useSubscribe(): ({
  subscriptionCodeName,
}: {
  subscriptionCodeName: string;
}) => Promise<void> {
  const { makeAuthenticatedPostRequest } = useMakeAuthenticatedPostRequest();
  const { refreshCurrentUser } = useAuth();

  return async ({ subscriptionCodeName }: { subscriptionCodeName: string }) => {
    const { data } = await makeAuthenticatedPostRequest(
      `/api/account/subscription/subscribe`,
      { subscriptionCodeName },
    );
    await refreshCurrentUser();
    return data;
  };
}

export function useUnsubscribe(): () => Promise<void> {
  const { makeAuthenticatedPostRequest } = useMakeAuthenticatedPostRequest();
  const { refreshCurrentUser } = useAuth();

  return async () => {
    const { data } = await makeAuthenticatedPostRequest(
      `/api/account/subscription/unsubscribe`,
    );
    await refreshCurrentUser();
    return data;
  };
}

export function useResubscribe(): () => Promise<void> {
  const { makeAuthenticatedPostRequest } = useMakeAuthenticatedPostRequest();
  const { refreshCurrentUser } = useAuth();

  return async () => {
    const { data } = await makeAuthenticatedPostRequest(
      `/api/account/subscription/resubscribe`,
    );
    await refreshCurrentUser();
    return data;
  };
}
