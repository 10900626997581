import { SearchSelect, SearchSelectItem, Select, SelectItem } from '@tremor/react';
import { useEvents, usePages } from '../common';
import { DateRange } from '../../../../requests/api/common.interfaces';
import { SkeletonLoader } from '../../../../components';
import { useEffect, useState } from 'react';

function createEventFilter(eventName: string): GoalFilter {
  return { key: 'eventName', matchKey: 'equals', value: eventName };
}

function createPageFilter(pagePath: string): GoalFilter {
  return { key: 'pagePath', matchKey: 'equals', value: pagePath };
}

export interface GoalFilter {
  key: 'eventName' | 'pagePath';
  matchKey: 'equals';
  value: string;
}

export default function GoalFilterBar({
  websiteId,
  dateRange,
  goalFilter,
  onGoalFilterChange,
}: {
  websiteId: string;
  dateRange: DateRange;
  goalFilter: GoalFilter | null;
  onGoalFilterChange: (goalFilter: GoalFilter | null) => void;
}) {
  const [filterKey, setFilterKey] = useState<'eventName' | 'pagePath'>('eventName');
  const [eventName, setEventName] = useState<string | null>(null);
  const [pagePath, setPagePath] = useState<string | null>(null);

  const events = useEvents({
    websiteId,
    dateRange,
    queryKey: 'goal-filter-events',
  });
  const pages = usePages({
    websiteId,
    dateRange,
    queryKey: 'goal-filter-pages',
  });

  useEffect(() => {
    if (goalFilter) {
      setFilterKey(goalFilter.key);
      if (goalFilter.key === 'eventName') {
        setEventName(goalFilter.value);
      } else {
        setPagePath(goalFilter.value);
      }
    }
  }, [goalFilter]);

  const onFilterKeyChange = (key: 'eventName' | 'pagePath') => {
    setFilterKey(key);

    const filter =
      key === 'eventName' && eventName
        ? createEventFilter(eventName)
        : key === 'pagePath' && pagePath
          ? createPageFilter(pagePath)
          : null;

    onGoalFilterChange(filter);
  };

  const onEventNameChange = (value: string) => {
    setEventName(value);

    const filter = value ? createEventFilter(value) : null;

    onGoalFilterChange(filter);
  };

  const onPagePathChange = (value: string) => {
    setPagePath(value);

    const filter = value ? createPageFilter(value) : null;

    onGoalFilterChange(filter);
  };

  return (
    <div className="space-y-4 py-5 md:py-0 md:pb-4">
      <div className="text-center text-tremor-content">
        <h1 className="text-tremor-title">
          Which
          <select
            title="Filter type"
            value={filterKey}
            onChange={e => onFilterKeyChange(e.target.value as 'eventName' | 'pagePath')}
            className="-mr-2 ml-1 h-8 w-20 border-none bg-transparent p-0 text-tremor-title focus:ring-0"
          >
            <option value="eventName">event</option>
            <option value="pagePath">page</option>
          </select>
          would you like to track?
        </h1>
        <p className="text-tremor-label">
          {filterKey === 'eventName'
            ? 'Discover which visitors triggered a specific event.'
            : 'Discover which visitors viewed a specific page.'}
        </p>
      </div>
      {filterKey === 'eventName' ? (
        <div className="flex justify-center">
          {events.isLoading && (
            <SkeletonLoader className="mb-1 mt-0.5 h-8 w-full md:max-w-sm" />
          )}
          {events.isError && (
            <p className="text-center text-tremor-label text-red-600">
              An error occurred while retrieving the event list. <br /> Try refreshing the
              page.
            </p>
          )}
          {events.isSuccess && (
            <Select
              className="md:max-w-sm"
              value={eventName ?? undefined}
              onValueChange={onEventNameChange}
            >
              {events.data.items.map(event => (
                <SelectItem key={event.name} value={event.name}>
                  {event.name}
                </SelectItem>
              ))}
            </Select>
          )}
        </div>
      ) : (
        <div className="flex justify-center">
          {pages.isLoading && (
            <SkeletonLoader className="mb-1 mt-0.5 h-8 w-full md:max-w-sm" />
          )}
          {pages.isError && (
            <p className="text-center text-tremor-label text-red-600">
              An error occurred while retrieving the page list. <br /> Try refreshing the
              page.
            </p>
          )}
          {pages.isSuccess && (
            <SearchSelect
              className="md:max-w-sm"
              value={pagePath ?? undefined}
              onValueChange={onPagePathChange}
            >
              {pages.data.items.map(page => (
                <SearchSelectItem key={page.path} value={page.path}>
                  {page.path}
                </SearchSelectItem>
              ))}
            </SearchSelect>
          )}
        </div>
      )}
    </div>
  );
}
